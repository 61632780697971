import { React, useEffect, useState } from 'react'
import img_1 from '../Photos/image_1.jpg'
import img_2 from '../Photos/image_2.jpg'
import img_3 from '../Photos/image_3.jpg'
import './hero.css'
import CategoryData from '../Category/CategoryData'
import Product from '../MainProduct/Product'
import { ProductList } from '../ProductCart/ProductList'
import NewSlider from '../Testimonials/NewSlider'
import MoreInfo from './MoreInfo'
import Benefits from './Benefits'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import fb from '../Photos/fb.svg'
import insta from '../Photos/insta.svg'
import twit from '../Photos/twitter.svg'
import YouTube from '../YouTubeVideo/YouTube'
import Reviews from '../AdminReview/Reviews'

const HeroSection = ({ user_id, role }) => {

    const navigate = useNavigate();

    const [images, setImages] = useState([]);

    const handleedithero = () => {
        navigate('/edit_herosection')
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_herosection`);
                if (res.status === 200) {
                    setImages(res.data);
                    // console.log("Hero Ka Data : ", res.data)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        fetchData();
    }, []);


    const handledeleteimg = async (e, id) => {
        // console.log("Delete ID:", id)
        try {
            // // console.log();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/delete_heroimage/${id}`,
            );
            // console.log(response.data);
            if (response.data.success = true) {
                alert("Deleted Successfully")
                window.location.reload();
            }
        } catch (error) {
            // console.error("Error:", error);
        }
    }
    return (
        <>
            {role == 1 ? (
                <div className="top_hero_buttons mb-2">
                    {/* <button className='btn btn-warning col-md-4' onClick={handleedithero}>Add Images</button>
                    {images.map((data) => (
                        <button key={data.id} className='btn btn-danger col-md-4' onClick={(e, data_id) => handledeleteimg(e, data.id)}>Delete</button>
                    ))} */}
                </div>
            ) : (<>
            </>)}

            <ProductList user_id={user_id} />
            {/* <NewSlider/> */}
            <br />
            {/* <Benefits/> */}

            <div className="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <div class="col-md-4 d-flex align-items-center">
                        <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
                            <svg class="bi" width="30" height="24"></svg>
                        </a>
                        <span class="mb-3 mb-md-0 text-body-secondary">© Pop N Plate</span>
                    </div>

                    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                        <li class="ms-3"><a class="text-body-secondary" href="#"><img class="bi" width="24" height="24" src={fb}></img></a></li>
                        <li class="ms-3"><a class="text-body-secondary" href="#"><img class="bi" width="24" height="24" src={insta}></img></a></li>
                        <li class="ms-3"><a class="text-body-secondary" href="#"><img class="bi" width="24" height="24" src={twit}></img></a></li>
                    </ul>
                </footer>
            </div>


        </>
    )
}

export default HeroSection
