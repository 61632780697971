import { React, useState, useEffect } from 'react';
import { FaShoppingCart, FaMapMarkerAlt, FaNotesMedical, FaUserSecret, FaTimes, FaSave, FaPersonBooth, FaBook, FaStar, FaQuestionCircle, FaArrowLeft } from 'react-icons/fa';
import './profile.css'
import MyCart from '../MyCart/MyCart';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ReactStars from "react-rating-stars-component";
import { toast } from 'sonner';

const Content = ({ activeOption, data, handleOptionClick, uid }) => {

    const [info, setInfo] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const [passedit, setPassEdit] = useState(false);
    const [updatedInfo, setUpdatedInfo] = useState([]);
    const [productdata, setProductData] = useState([]);
    const [orderstatus, setOrderStatus] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [orderArray, setOrderArray] = useState([]);
    const [reviewData, setReviewData] = useState({
        rating: 1,
        notes: '',
    });

    const navigate = useNavigate();
    const [statedata, setStateData] = useState([

        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ])

    // console.log("user id aala re : ", uid)

    const ratingChanged = (newRating) => {
        // console.log(newRating);
        setReviewData(prevState => ({
            ...prevState,
            rating: newRating,
        }));
    };

    const handleOpenModal = (img, name, product_id) => {
        setReviewData({ ...reviewData, productImg: img, productName: name, product_id: product_id });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleSaveReview = async () => {
        // Send reviewData to API
        // You can add your API call logic here
        // console.log('Review Data:', reviewData);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/save_review/${uid}`, reviewData);
            // setInfo(res.data);
            // console.log("Updated Review : ", res.data)
            toast.success("Thank You!!")
            window.location.reload()
            setEditMode(false);
        } catch (error) {
            // console.log("Error fetching user data:", error);
        }
        // handleCloseModal(); // Close modal after saving
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_user_data/${uid}`);
                setInfo(res.data);
                // console.log("User Data : ", info)
            } catch (error) {
                // console.log("Error fetching user data:", error);
            }
        };

        fetchData();
    }, [uid]);

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleEditPass = (e) => {
        e.preventDefault();
        setPassEdit(true)
    }

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        setUpdatedInfo(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleReviewChange = (e, field) => {
        const value = e.target.value;
        setReviewData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleCancelClick = () => {
        setEditMode(false);
        setUpdatedInfo(info); // Reset updatedInfo to current data when canceling edits
    };
    const handleCancelPass = () => {
        setPassEdit(false);
        setUpdatedInfo(info); // Reset updatedInfo to current data when canceling edits
    };

    const handleSaveClick = async () => {

        // console.log("Updated Info:", updatedInfo)
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_address/${uid}`, updatedInfo);
            // setInfo(res.data);
            // console.log("Updated Info : ", res.data)
            window.location.reload()
            setEditMode(false);
        } catch (error) {
            // console.log("Error fetching user data:", error);
        }


    };

    const handleSavePersonalDetails = async () => {

        // console.log("Updated Info:", updatedInfo)
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_personal_details/${uid}`, updatedInfo);
            // setInfo(res.data);
            // console.log("Updated Info : ", res.data)
            window.location.reload()
            setEditMode(false);
        } catch (error) {
            // console.log("Error fetching user data:", error);
        }


    };

    const handleUpdatePassword = async () => {

        if (updatedInfo.password !== updatedInfo.confirmpassword) {
            alert("Password Doesn't matches")
        }
        // console.log("Updated Info:", updatedInfo)

        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_password/${uid}`, updatedInfo);
            // setInfo(res.data);
            // console.log("Updated Info : ", res.data)
            window.location.reload()
            setEditMode(false);
        } catch (error) {
            // console.log("Error fetching user data:", error);
        }


    };

    const handleContinueShopping = async () => {
        navigate('/')
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_order_history/${uid}`
                );
                // // console.log("Order History: ", res.data);
                setProductData(res.data);

                // console.log(orderstatus)
              
            } catch (error) {
                // console.error("Error fetching order history:", error.message);
            }
        };

        fetchData();
    }, [uid]);

    useEffect(() => {
        // console.log("Products Indi info : ", productdata);
    }, [productdata]); // Run this useEffect whenever productdata changes



    // console.log(activeOption)

    const handledownloadinvoice = (e, products, pid, total_price) => {
        const updatedOrderArray = [products]; // Store only the new product as the array element
        setOrderArray(updatedOrderArray);
        const dataArray = Object.values(products);
        // console.log(orderArray);
        if (orderArray.length > 0)
            navigate('/download_invoice', { state: { userData: orderArray, total_price: total_price } })

        // window.open('./Bill.html','_blank')
    };

    switch (activeOption) {
        case 'dashboard':
            return (
                <div className='container container d-flex justify-content-center align-items-center'>
                    <div className="dashboard">
                        <div className="card">
                            <div className="car_body">
                                <div className="order_content">
                                    <span className="icon-container">
                                        <FaUserSecret className="icon" />
                                    </span>


                                    <p onClick={() => handleOptionClick('personal_details')}>Personal Details</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card_body">
                                <div className="order_content">
                                    <span className="icon-container">
                                        <FaShoppingCart className="icon" />
                                    </span>
                                    <p onClick={() => handleOptionClick('orders')}>Orders</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="car_body">
                                <div className="order_content">
                                    <span className="icon-container">
                                        <FaMapMarkerAlt className="icon" />
                                    </span>
                                    <p onClick={() => handleOptionClick('address')}>Address</p>
                                </div>
                            </div>
                        </div>


{/* Ye Baki Hai  */}


                        {/* <div className="card">
                            <div className="car_body">
                                <div className="order_content">
                                    <span className="icon-container">
                                        <FaStar className="icon" />
                                    </span>
                                    <p onClick={() => handleOptionClick('reviews')}>Reviews</p>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="car_body">
                                <div className="order_content">
                                    <span className="icon-container">
                                        <FaQuestionCircle className="icon" />
                                    </span>
                                    <p onClick={() => handleOptionClick('faq')}>FAQ's</p>
                                </div>
                            </div>
                        </div> */}


                    </div>

                </div>


            );

        case 'orders':
            return (
                <div className='order_top_card'>
                    <div className="outer_order_card">
                    {productdata.length > 0 ? (
    <div className="ordered_card d-flex flex-column justify-content-center align-items-center">
        {productdata.map((item, i) => (
            <div className="order_card mb-5" style={{ width: "100%", height: 'fit-content' }} key={i}>
                <div className="card mb-3" style={{ width: "100%", height: '100%' }}>
                    <div className="row g-0">
                        <div className="col-md-4 order_img">
                            <img className='img-fluid rounded-start' src={`${process.env.REACT_APP_BASE_URL}/view_doc/${item.product_img}`} style={{ boxShadow: '4px 4px 4px #00000061' }} />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-title">{item.product_name}</h5>
                                    {item.status === 3 ? (
                                        <div className='d-flex flex-col-gap-5'>
                                            <button className='btn btn-warning btn-sm mx-2' style={{ borderRadius: '50px' }} onClick={() => handleOpenModal(item.product_img, item.product_name, item.product_id)}>Add Review</button>
                                            {/* <button className='btn btn-warning btn-sm' style={{ borderRadius: '50px' }} onClick={(e) => handledownloadinvoice(e, item, item.total_price)}>Invoice</button> */}
                                        </div>
                                    ) : (
                                        <button className='btn btn-warning btn-sm' style={{ borderRadius: '50px' }}></button>
                                    )}
                                </div>
                                <p className='card-text' style={{ fontSize: '15px' }}>Size: {item.product_size}</p>
                                <p className='card-text mt-0' style={{ fontSize: '15px' }}>Quantity: {item.quantity}</p>
                                <p className='card-text mt-0' style={{ fontSize: '15px' }}>Price: ₹{item.total_price}/-</p>
                                <p className='card-text' style={{ fontSize: '15px' }}>Order Status: {item.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
                <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                        <Modal.Title>Add Review</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="review_content">
                                            <div className='review_img_content d-flex justify-content-center flex-column align-items-center'>
                                                <img className='img-fluid  d-flex justify-content-center flex-column align-items-center' src={`${process.env.REACT_APP_BASE_URL}/view_doc/${reviewData.productImg}`} style={{ width: '10rem', boxShadow: '4px 4px 4px 4px rgb(0,0,0.6)' }} />
                                                <br />
                                                <h5>{reviewData.productName}</h5>

                                            </div>



                                            <div>
                                                <div class="mb-3 d-flex justify-content-between align-items-center" style={{width:'280px'}}>
                                                    <label for="exampleInputEmail1" class="form-label"><b>Give rating: </b></label>
                                                    <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={20}
                                                        value={reviewData.rating}
                                                        activeColor="#ffd700"
                                                        className='mt-0'
                                                    // onChange={(e)=>handleReviewChange(e.target.value,'rating')}
                                                    />
                                                </div>

                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1" class="form-label"><b>Short Review: </b></label>
                                                    <textarea class="form-control" value={reviewData.notes} onChange={(e) => handleReviewChange(e, 'notes')} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>


                                            </div>

                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                                        <button className="btn btn-primary" onClick={handleSaveReview}>Save</button>
                                    </Modal.Footer>
                                </Modal>
    </div>
) : (
    <div className="text-center">
        <h4>Let's Do the Shopping..!!</h4>
        <button className='btn btn-warning continue_shopping_btn mt-5' onClick={handleContinueShopping}>
            <FaArrowLeft className="mr-2" /> Continue Shopping
        </button>
    </div>
)}

                    </div>
                </div>

       


            );

        case 'personal_details':
            return (
                <div className='container'>
                    <div className="category_head mt-0">
                        <h4>My Details</h4>
                    </div>

                    {info.map((data, i) => (

                        <form class="row g-3 mt-2 d-flex justify-content-center" style={{ margin: '0px 20px;' }}>

                            <div class="col-md-5">
                                <label for="inputAddress" class="form-label">First Name</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputfirstName" value={data.firstName} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputfirstName" value={updatedInfo.firstName} onChange={(e) => handleInputChange(e, 'firstName')} />
                                )}
                            </div>

                            <div class="col-md-5">
                                <label for="inputAddress" class="form-label">Last Name</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputLastName" value={data.lastName} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputLastName" value={updatedInfo.lastName} onChange={(e) => handleInputChange(e, 'lastName')} />
                                )}
                            </div>


                            <div class="col-md-5 mt-4">
                                <label for="inputAddress" class="form-label">Email</label>
                                {!editMode ? (
                                    <input type="email" className="form-control" id="inputEmail" value={data.email} readOnly />
                                ) : (
                                    <input type="email" className="form-control" id="inputEmail" value={updatedInfo.email} onChange={(e) => handleInputChange(e, 'email')} />
                                )}
                            </div>

                            <div class="col-md-5 mt-4">
                                <label for="inputAddress" class="form-label">Contact Number</label>
                                {!editMode ? (
                                    <input type="number" className="form-control" id="inputContactNumber" value={data.phoneNumber} readOnly />
                                ) : (
                                    <input type="number" className="form-control" id="inputContactNumber" value={updatedInfo.phoneNumber} onChange={(e) => handleInputChange(e, 'phoneNumber')} />
                                )}
                            </div>
                            {!editMode && (
                                <div class="d-flex justify-content-center gap-3 mt-5">
                                    <button className='btn btn-warning col-md-5' onClick={handleEditClick}>Update Details</button>
                                    <button className='btn btn-danger col-md-5' onClick={(e) => handleEditPass(e)}>Change Password</button>

                                </div>
                            )}





                            {passedit && (
                                <>

                                    <div class="col-md-5 mt-4">
                                        <label for="inputAddress" class="form-label">New Password</label>

                                        <input type="text" className="form-control" id="inputPassword" value={updatedInfo.password} onChange={(e) => handleInputChange(e, 'password')} />

                                    </div>
                                    <div class="col-md-5 mt-4">
                                        <label for="inputAddress" class="form-label">Confirm Password</label>

                                        <input type="text" className="form-control" id="inputConfirmPassword" value={updatedInfo.confirmpassword} onChange={(e) => handleInputChange(e, 'confirmpassword')} />

                                    </div>
                                </>
                            )}
                            {passedit && (
                                <div class="d-flex justify-content-center gap-3 mt-3">
                                    <button type="button" className="btn btn-danger mx-2" onClick={handleCancelPass}>
                                        <FaTimes /> Cancel
                                    </button>
                                    <button type="button" className="btn btn-warning mx-2" onClick={handleUpdatePassword}>
                                        <FaSave /> Save
                                    </button>
                                </div>
                            )}
                            {editMode && (
                                <div class="d-flex justify-content-center gap-3 mt-5">
                                    <button type="button" className="btn btn-danger mx-2" onClick={handleCancelClick}>
                                        <FaTimes /> Cancel
                                    </button>
                                    <button type="button" className="btn btn-warning mx-2" onClick={handleSavePersonalDetails}>
                                        <FaSave /> Save
                                    </button>
                                </div>
                            )}
                            <div class="col-12">
                            </div>


                        </form>
                    ))}


                </div>
            );

        case 'address':
            return (
                <div className='container'>
                    <div className="category_head mt-0">
                        <h4>My Address</h4>
                    </div>
                    {info.map((data, i) => (
                        <form class="row g-3 mt-0">
                            <div class="col-12">
                                <label for="inputAddress" class="form-label">House/Apartment No.</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputAddress" value={data.address1} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputAddress" value={updatedInfo.address1} onChange={(e) => handleInputChange(e, 'address1')} />
                                )}
                            </div>
                            <div class="col-md-6">
                                <label for="inputAddress2" class="form-label">Area</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputAddres2" value={data.address2} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputAddres2" value={updatedInfo.address2} onChange={(e) => handleInputChange(e, 'address2')} />
                                )}
                            </div>
                            <div class="col-md-6">
                                <label for="inputAddress2" class="form-label">Landmark</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputLandmark" value={data.landmark} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputLandmark" value={updatedInfo.landmark} onChange={(e) => handleInputChange(e, 'landmark')} />
                                )}
                            </div>
                            <div class="col-md-6">
                                <label for="inputCity" class="form-label">City</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputCity" value={data.city} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputCity" value={updatedInfo.city} onChange={(e) => handleInputChange(e, 'city')} />
                                )}
                            </div>
                            <div class="col-md-4">
                                <label for="inputState" class="form-label">State</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputState" value={data.state} readOnly />
                                ) : (
                                    <select id="inputState" className="form-select" name="state" onChange={(e) => handleInputChange(e, 'state')} >
                                        <option selected>Choose...</option>
                                        {statedata.map((data, i) => (
                                            <option value={data}>{data}</option>

                                        ))}
                                    </select>
                                )}
                            </div>
                            <div class="col-md-2">
                                <label for="inputZip" class="form-label">PinCode</label>
                                {!editMode ? (
                                    <input type="text" className="form-control" id="inputCode" value={data.pincode} readOnly />
                                ) : (
                                    <input type="text" className="form-control" id="inputCode" value={updatedInfo.pincode} onChange={(e) => handleInputChange(e, 'pincode')} />
                                )}
                            </div>

                            {!editMode && (
                                <div className="col-12 text-center mt-5">
                                    <button type="button" className=" col-12 btn btn-warning" onClick={handleEditClick}>
                                        Edit
                                    </button>
                                </div>
                            )}
                            {editMode && (
                                <div className="col-12 text-center mt-5">
                                    <button type="button" className="btn btn-danger mx-2" onClick={handleCancelClick}>
                                        <FaTimes /> Cancel
                                    </button>
                                    <button type="button" className="btn btn-warning mx-2" onClick={handleSaveClick}>
                                        <FaSave /> Save
                                    </button>
                                </div>
                            )}

                        </form>
                    ))}


                </div>
            );

        // case 'reviews':
        //     return (
        //         <div>
        //             <h3>Reviews Content</h3>
        //             <p>This is the customers page content.</p>
        //         </div>
        //     );

        default:
            return null;
    }
};

export default Content;
