import React from 'react'
import logo from '../Photos/logo.png'
import { useState } from 'react';
import Modal from '../Login/Modal';
import { FaShoppingCart, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import LoginModal from '../Login/Modal';



const AdminNavBar = ({ handleUserLogin, handleLogout, user_id, token, role }) => {

    const navigate = useNavigate();
    const [count, setCount] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    function handleLogout() {
        window.open('/', '_self');
        localStorage.clear();
        // window.location.reload();

    }
    const handleShopCart = () => {
        console.log("user_id", user_id)
        navigate('/mycart', { state: { user_id: user_id } });

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_cart_count/${user_id}`
                );
                if (res.status === 200) {
                    setCount(res.data.count);
                }
            } catch (error) {
                console.error("Error fetching cart count:", error.message);
            }
        };

        fetchData();
    }, [user_id]);

    return (
        <>
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom"
                    style={{ backgroundColor: 'white' }}>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <a href="/" className="d-inline-flex mx-5 link-body-emphasis text-decoration-none">
                            <img className="bi" src={logo} width="100" height="100" role="img" aria-label="Bootstrap"></img>
                        </a>
                    </div>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><Link to="/" className="nav-link px-2 link-dark">Home</Link></li>
                        <li><Link to="/inventory" className="nav-link px-2 link-dark">Inventory</Link></li>
                        <li class="nav-item dropdown">
                            <li class="nav-link dropdown-toggle text-dark" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Master
                            </li>
                            <ul class="dropdown-menu">
                                <Link class="dropdown-item" to="/add_product">Add Product</Link>
                                <Link class="dropdown-item" to="/add_category">Add Category</Link>
                                <Link class="dropdown-item" to="/register">Add User</Link>
                                <Link class="dropdown-item" to="/add_user_orders">Add User Orders</Link>
                            </ul>
                        </li>
                        <li><Link to="/placed_orders" className="nav-link px-2 link-dark">Confirm Orders</Link></li>
                        {/* '/admin_reviews */}
                        {/* <li><Link to="/admin_reviews" className="nav-link px-2 link-dark">Reviews</Link></li> */}
                        <li><Link to="/profile" className="nav-link px-2 link-dark">Profile</Link></li>
                    </ul>
                    <div className="col-md-3 text-end">
                        <button type="button" className="btn btn-primary position-relative mx-2" onClick={handleShopCart}>
                            {count > 0 ?
                                (<>
                                    <FaShoppingCart />Cart
                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                        {count}
                                        <span className="visually-hidden">unread messages</span>
                                    </span>
                                </>) :
                                (
                                    <>
                                        <FaShoppingCart />Cart
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            0
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </>
                                )}

                        </button>

                        {/* Render CartModal only when isModalOpen is true */}

                        {/* Render login/logout buttons based on user_id */}
                        {user_id > 0 ? (
                            <button type="button" className="btn btn-outline-danger me-5" onClick={handleLogout}>Logout</button>
                        ) : (
                            <>
                                <button type="button" className="btn btn-outline-danger me-2" onClick={openModal}>Login</button>
                                {/* <Modal isOpen={isModalOpen} onClose={closeModal} handleUserLogin={handleUserLogin} token={token} /> */}
                                <LoginModal isOpen={isModalOpen} onClose={closeModal} handleUserLogin={handleUserLogin} token={token} />

                                <Link to='/register'><button type="button" className="btn btn-danger">Register</button></Link>
                            </>
                        )}
                    </div>

                </header>
            </div>
        </>
    )
}

export default AdminNavBar
