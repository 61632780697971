import React, { useState, useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './ConfirmOrder.css'; // Custom CSS for styling
import Confetti from 'react-confetti';
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


const ConfirmOrder = ({ email }) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
const navigate=useNavigate();
  useEffect(() => {
    setShowAnimation(true); // Trigger animation on component mount

    // Disable confetti after 10 seconds
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(timer); // Clear the timer on component unmount
    };
  }, []);

  const handlenavigate=()=>{
    window.open('/','_self')
    // window.location.reload()
  }
  return (
    <>
      {showConfetti && <Confetti />} {/* Show confetti if showConfetti is true */}
      <div className="container" style={{marginTop:'5%'}}>
        <div className={`order-confirmed-container ${showAnimation ? 'show-animation' : ''}`}>
          <div className="confirmation-icon">
            <FaCheckCircle size={100} color="green" className="check-icon" />
          </div>
          <div className="confirmation-text mt-5">
            <h2>Order Confirmed</h2>
            <p>You will receive an email to {email} shortly.</p>
            <button className='btn btn-warning' onClick={handlenavigate}> <FaArrowLeft /> Continue Shopping</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
