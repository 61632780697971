import { React, useState, useEffect } from 'react'
import './register.css'
import axios from 'axios'
import { Toaster, toast } from 'sonner'
import { useNavigate } from 'react-router-dom'

const Register = () => {


    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        password: '',
        confirmPassword: '',
        latitude: null,
        longitude: null,
    });

    const [statedata, setStateData] = useState([

        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = async (e) => {
        // console.log("req url : ", process.env)
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('Password and Confirm Password do not match!');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/register`, formData);
            // console.log('Form submitted successfully:', response.data);

            if (response.data.success === false) {
                toast.warning(response.data.message)
            }
            else {
                toast.success('Registartion Done Successfully')
                setTimeout(() => {
                    navigate('/');
                }, 2500);
            }


        } catch (error) {
            // console.error('Error submitting form:', error);
            // Handle form submission error (e.g., display error message to the user)
        }
    };

    const handleLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setFormData(prevState => ({
                        ...prevState,
                        latitude: latitude,
                        longitude: longitude,
                    }));
                },
                error => {
                    // console.error('Error getting location:', error);
                    // Handle location retrieval error (e.g., display an error message)
                }
            );
        } else {
            // console.error('Geolocation is not supported by this browser.');
            // Handle geolocation not supported error (e.g., display an error message)
        }
    };
    

    return (
        <>

            <div className="container">
                <Toaster position="top-center" richColors />
                <div className="register_head mb-3">
                    <h2>Welcome User</h2>
                    <p>Create account here...</p>
                </div>

                <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <label for="first_name" className="form-label">First Name</label>
                        <input type="text" className="form-control" name="firstName" id="inputFirstName" onChange={handleChange} />
                    </div>

                    <div className="col-md-6">
                        <label for="last_name" className="form-label">Last Name</label>
                        <input type="text" className="form-control" name="lastName" id="inputLastName" onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label for="inputEmail4" className="form-label">Email</label>
                        <input type="email" className="form-control" name="email" id="inputEmail4" onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                        <label for="phone_no" className="form-label">Phone No.</label>
                        <input type="number" className="form-control" name="phoneNumber" id="inputPhoneNumber" onChange={handleChange} />
                    </div>
                    {/* <div className="col-md-2 mt-5">
                        <button className='btn btn-warning'>Get OTP</button>
                    </div> */}
                    <div className="col-12">
                        <label for="inputAddress" className="form-label">House No./Apartment Name</label>
                        <input type="text" className="form-control" id="inputAddress" name="address1" placeholder="House name and Street name" onChange={handleChange} />
                    </div>

                    <div className="col-md-6">
                        <label for="inputAddress2" className="form-label">Area</label>
                        <input type="text" className="form-control" id="inputAddress2" name="address2" placeholder="XYZ Area" onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label for="inputLandmark" className="form-label">LandMark</label>
                        <input type="text" className="form-control" id="inputLandmark" name="landmark" placeholder="Landmark" onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label for="inputCountry" className="form-label">Country</label>
                        <input type="text" className="form-control" name="country" id="inputCountry" onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label for="inputCity" className="form-label">City</label>
                        <input type="text" className="form-control" name="city" id="inputCity" onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                        <label for="inputState" className="form-label">State</label>
                        <select id="inputState" className="form-select" name="state" onChange={handleChange} >
                            <option selected>Choose...</option>
                            {statedata.map((data, i) => (
                                <option value={data}>{data}</option>

                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label for="inputZip" className="form-label">PinCode</label>
                        <input type="text" className="form-control" name="pincode" id="inputZip" onChange={handleChange} />
                    </div>

                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={handleLocationClick}>Get Live Location</button>
                    </div>


                    <div className="col-md-6">
                        <label for="inputPassword4" className="form-label">Password</label>
                        <input type="password" className="form-control" name='password' id="inputPassword4" onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label for="inputConfimPassword" className="form-label">Confirm Password</label>
                        <input type="password" className="form-control" name="confirmPassword" id="inputConfirmPassword" onChange={handleChange} />
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck" />
                            <label className="form-check-label" for="gridCheck">
                                Check me out
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-warning">Sign in</button>
                    </div>
                </form>



            </div>
        </>
    )
}

export default Register
