import React from 'react'
import './mycart.css'
import { useState, useEffect } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';
import { Toaster, toast } from 'sonner'

const MyCart = () => {

    const [quantity, setQuantity] = useState(1);
    const [data, setData] = useState([]);
    const [product_id,setProductID]=useState(0);
    const [cart_id,setCart_id]=useState([]);
    const [Productdata,setProductData]=useState([]);
    const [totalPriceOverall, setTotalPriceOverall] = useState(0);
    const navigate = useNavigate();

    const location = useLocation();
    const id = location.state?.user_id || [];
    // // console.log("ID mila : ", id)


    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };


    const calculateTotalPrice = (cartItems) => {
        // // console.log("Function mai data : ",cartItems)
        let totalPrice = 0;
        cartItems.forEach((item) => {
            totalPrice += item.total_price;
        });
        setTotalPriceOverall(totalPrice);
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const uid=id;
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/getaddcartproducts/${uid}`
                );
                // console.log("Cart Data: ", res.data);
                if (res.status === 200) {
                    // Assuming res.data is an object with a key 'count'
                    setData(res.data);
                    const cartIds = res.data.map((item) => item.cart_id);
                    setCart_id(cartIds)
                    const productData = res.data.map(item => ({
                        product_id: item.product_id,
                        quantity: item.quantity,
                        total_price: item.total_price
                    }));
                    const productID = res.data.map(item => ({
                        product_id: item.product_id,
                    }));
                    setProductID(productID)
                    setProductData(productData)
                    // // console.log("Products Indi info : ",Productdata)
                    
                    calculateTotalPrice(res.data);
                    // // console.log("Cart ka data: ", res.data);
                }
            } catch (error) {
                // // console.error("Error assigning subject data:", error.message);
            }
        };

        fetchData();





    }, [id]);

    const handledeleteitem = async (e, product_id, id) => {
        // // console.log("Delete clicked", e.target.value, "ID : ", product_id, "user id", id)


        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/remove_cart/${product_id}/${id}`
            );
            // // console.log("Deleted", res);
            if (res.status === 200) {
                // Assuming res.data is an object with a key 'count'
                alert("Item removed")
                window.location.reload();
            }
        } catch (error) {
            // // console.error("Error assigning subject data:", error.message);
        }
    }


    const handlenavigate = () => {

        // // console.log("Cart ID : ",cart_id)
        navigate('/checkout',{ state: { user_id: id,cartIds:cart_id,productdata:data,product_id:product_id} })
    }


    const handleContinueShopping=()=>{
        navigate('/')
    }
    return (
        <div className="container" style={{marginTop:'5%'}}>
              {/* <Toaster position="top-center" richColors /> */}
            <div className="cart_container">
                <div className="first_content">
                    <div className="first_content_header">
                        <h3>Shopping Cart</h3>
                        {/* <h4>Total Price Overall: ₹ {totalPriceOverall}/-</h4> */}

                    </div>
                    {/* <hr/> */}
                    <div className="main_cart_data">



                        {data.length > 0 ? (
                            <table className="table custom_table">
                                <thead>
                                    <tr>
                                        <th scope='col'></th>
                                        <th scope='col'>IMG</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Size</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => (
                                        <tr key={i}>
                                            <td></td>
                                            <td><img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${item.product_img}`} style={{ maxWidth: '7rem', boxShadow: '4px 4px 4px #00000061' }} /></td>
                                            <td>{item.product_name}</td>
                                            <td>{item.product_size}</td>
                                            <td>{item.quantity}</td>
                                            <td>₹ {item.total_price}</td>
                                            <td><button className='btn btn-danger btn-sm' onClick={(e) => handledeleteitem(e, item.product_id, id)}>Delete</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <h4>Let's Do the Shopping..!!</h4>
                                <button className='btn btn-warning continue_shopping_btn mt-5' onClick={handleContinueShopping}>
                                    <FaArrowLeft className="mr-2" /> Continue Shopping
                                </button>
                            </div>
                        )}




                        {data.length > 0 ? (
                            <>
                            <div className="text-center">
                                <div className="price_content mt-5">
                                    <h5>Total Price : ₹ {totalPriceOverall}/- </h5>
                                </div>

                                <div className="checkout_btn mt-4">
                                    <button className='btn btn-warning' onClick={(e) => handlenavigate(data.cart_id,data)}>
                                    Confirm Order <FaArrowRight className="mx-2"/> </button>
                                </div>
                               
                               
                                </div>
                                <button className='btn btn-warning continue_shopping_btn mt-5' onClick={handleContinueShopping}>
                                    <FaArrowLeft className="mr-2" /> Continue Shopping
                                </button>
                            </>
                        ) : (
                            <></>
                        )}



                    </div>
                </div>

            </div>
        </div>
    )
}

export default MyCart
