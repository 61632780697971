import React, { useState, useEffect } from 'react';
import '../AddProduct/addproduct.css';
import axios from 'axios';
import background from '../Photos/wave.svg'
import { useLocation } from 'react-router-dom';
import { Toaster, toast } from 'sonner'
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';
import { BsPlusCircle, BsXCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';


const EditCategory = () => {

    const [category, setCategory] = useState([]);
    const [mainproduct, setMainProduct] = useState([]);

    const location = useLocation();

    const id = location.state?.cat_id || [];
    console.log("Cat Id:",id)
    console.log(id)


    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_category_details/${id}`);
                if (res.status === 200) {
                    const mainInfo = res.data[0];

                    console.log("mainInfo:", res.data);

                    setFormData({                    
                        category: mainInfo.name || '',
                        image: mainInfo.cat_img
                    });
                    console.log("New form Data:", formData)
                }
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        };

      fetchInfo()
    }, [id]);

  




    const [formData, setFormData] = useState({
        category: '',
        image: '',
    });

    const formDataToSend2 = new FormData();

    const handleClickUpload = (index) => {
        const fileInput = document.getElementById(`file-upload-${index}`);
        fileInput.click();
    };
    const handleCancelImage = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [],
        }));
    };
    const handleImageUpload = async (e, index) => {
        const files = e.target.files; // Get all selected files
        console.log("Image for updation:", files)
        if (files.length > 0) {
            try {
                const category_data=id;
                const formDataToSend = new FormData();
                formDataToSend.append('image', files[0]);
                console.log("Image for updation:", formDataToSend)
                const response = await axios.post(
                   
                    `${process.env.REACT_APP_BASE_URL}/upload_image_cat/${category_data}`,
                    formDataToSend
                );

                if (response.status === 200) {
                    toast.success("Image Updated Successfully!")
                    // window.location.reload();
                } else {
                    console.error('Image upload failed:', response.data.message);
                }
            } catch (error) {
                console.error('Error uploading image:', error.message);
            }
        }
    };





    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeImage = (filelist) => {
        console.log("Images aayi : ", filelist);

        const updatedFormData = { ...formData }; // Create a copy of formData

        for (let index = 0; index < filelist.length; index++) {
            const file = filelist[index];
            updatedFormData.images.push(file); // Push the file to the images array in updatedFormData
        }

        // Update the formData state with the updatedFormData
        setFormData(updatedFormData);

        console.log("Form data with images:", updatedFormData);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
       
        formDataToSend.append('category', formData.category);
   
        console.log("New  List : ", formData);

     

        console.log("actual img:", formDataToSend);

        try {
             const cat_id = id;
             const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/update_category/${cat_id}`,
            formData
          );
             if (response.status == 200) {
                 toast.success("Edited Successfully")
             }
             window.open('/', '_self')
             console.log(response.data);
         } 
         catch (error) {
             console.error("Error:", error);
         }
    };



    return (
        <div className="add_product mb-2" style={{ marginTop: '3%' }}>
          
            <Toaster position="top-center" richColors />

            <div className="container">
            <div className="button mb-3">
                <Link to='/inventory'><button className='btn btn-warning'><FaArrowLeft/>Back</button></Link>
            </div>
                <div className="add_product_container">
                    <div className="register_head mb-3">
                        <h2>Edit Product</h2>
                        <p>Upload all the necessary info..</p>
                    </div>

                    <form className="row g-3" onSubmit={handleSubmit}>

                        {/* Preview area for selected images */}
                        <div className="d-flex justify-content-center align-items-center flex-wrap gap-5">

                            

                            <div className="col-md-3">
                                <div className="upload-square uploaded_square_img">
                                    <img className='col-md-3' src={`${process.env.REACT_APP_BASE_URL}/view_doc/${formData.image}`} alt="..." />

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="upload-square">
                                    <div className="upload-icon">
                                        <BsPlusCircle onClick={() => handleClickUpload(1)} />
                                        <input
                                            id={`file-upload-1`}
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                handleImageUpload(e, 1);
                                            }}
                                        />
                                    </div>
                                    <p className="upload-text">Upload New Image</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-12">
                            <label htmlFor="productName" className="form-label">Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="productName"
                                name="productName"
                                value={formData.category}
                                onChange={handleChange}
                            
                            />
                        </div>


                        <div className="col-12 mt-3 d-flex justify-content-center">
                            <button type="submit" className="btn btn-warning col-12" style={{ backgroundColor: '#095809' }}>Edit Category</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default EditCategory;
