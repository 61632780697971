import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios';
import './placedorder.css'
import GoogleMapReact from 'google-map-react';
import { Marker } from './Marker';
const PlacedOrders = () => {


    const [productList, setProductList] = useState([]);
    const [orderstatus, setOrderStatus] = useState([]);
    const [status_id, setStatusID] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/order_products`);
                if (res.status === 200) {
                    setProductList(res.data);
                    // console.log("Confirm Orders : ", res.data)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        const fetchOrderStatus = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_order_status`);
                if (res.status === 200) {
                    setOrderStatus(res.data);
                    // console.log("Confirm Orders : ", res.data)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        fetchData();
        fetchOrderStatus();
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleUpdateStatus = async (order_id, product_id, uid, quantity) => {
        // console.log("Data we get : ", order_id, product_id, uid, quantity, "Status id:", status_id);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_status/${order_id}/${uid}/${product_id}/${status_id}/${quantity}`);
            if (res.status === 200) {
                alert("Updated Successfully");
                window.location.reload()
            } else {
                alert("Insufficient Stock!"); // Show error message in alert
            }
        } catch (error) {
            // console.log("Error aahe bhava:", error);
            alert("Error updating status. Please try again later."); // Show error message in alert
        }
    }


    const handlechangestatus = (status_id) => {
        setStatusID(status_id)
        // console.log("Status Id hi aahe : ", status_id)
    }

    const handleLocationClick = (latitude, longitude) => {
        setSelectedLocation({ lat: latitude, lng: longitude });
    };


    return (



        <div className="placed_order_container">
            <div className="category_head" style={{marginTop:'0'}}>
                <h3>Confirmed Orders</h3>
            </div>
            <div className="table-container">
            <div className="inventory_table">
                <div className="table">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">O_ID</th>
                                <th scope="col">Product</th>
                                <th scope="col">P_ID</th>
                                <th scope="col">Category</th>
                                <th scope="col">Quant.</th>
                                <th scope="col">Size</th>
                                <th scope="col">Price</th>
                                <th scope="col">Order_Date</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Address</th>
                                <th scope="col">Contact</th>
                                <th scope="col">Status</th>
                                <th scope="col">Update Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList.map((data, i) => (
                                <tr className={`${data.status == 3 ? 'table-success' : 'table'}`} key={i}>
                                    <td>OR_{data.order_id}</td>
                                    <td id="table_img">
                                        <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.product_img}`} alt="..." />
                                    </td>
                                    <td>PR_{data.product_id}</td>
                                    <td>{data.product_category}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.product_size}</td>
                                    <td>₹{data.total_price}/-</td>
                                    <td style={{ fontSize: '14px' }}>{formatDate(data.order_date)}</td>
                                    <td style={{ fontSize: '14px' }}>Mr.{data.firstName} {data.lastName}</td>
                                    <td style={{ fontSize: '12px', width: '80px' }} onClick={() => handleLocationClick(data.latitude, data.longitude)}>{data.address1}, {data.street_add},{data.city} {data.state}-{data.pincode}</td>
                                    <td style={{ fontSize: '14px' }}>{data.phoneNumber}</td>
                                    <td style={{ fontSize: '14px' }}>{data.name}</td>
                                    <td style={{ fontSize: '14px' }}>
                                        <select class="form-select" aria-label="Default select example"
                                            onChange={(e) => handlechangestatus(e.target.value)}>
                                            <option selected>Update Status</option>
                                            {orderstatus.map((data, i) => (
                                                <option key={i} value={data.id}>{data.name}</option>
                                            ))}
                                        </select></td>
                                    <td>
                                        <button className='btn btn-warning btn-sm d-flex justify-center align-center' onClick={() => handleUpdateStatus(data.order_id, data.product_id, data.uid, data.quantity, data.id)}>Update</button>                                        </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            

        </div>
    )
}

export default PlacedOrders
