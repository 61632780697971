import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import './productmain.css';
import QuantitySelector from './QuantitySelector';
import { useLocation } from 'react-router-dom';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NewSlider from '../Testimonials/NewSlider';


const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const product_id = location.state?.product_id || [];

  // console.log("Product ID : ", product_id);

  const user_id = location.state?.user_id || [];
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [productInfo, setProductInfo] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);



  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setTotalPrice(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
    setTotalPrice(quantity + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_product_details/${product_id}`);
        if (res.status === 200) {
          setProductInfo(res.data);
          // console.log("Main data aya :", productInfo);
          const productImages = res.data.map(item => item.product_img);
         
          const allImages = [...productImages];
          setImages(allImages.filter(img => img !== '0'));
          // console.log("Images:", images);
        }
      } catch (error) {
        // console.error("Error fetching product data:", error.message);
      }
    };

    fetchData();
  }, [product_id]);


  useEffect(() => {
    // Calculate total price based on quantity
    const selectedProduct = productInfo[0];
    if (selectedProduct) {
      const price = parseFloat(selectedProduct.after_discount_price);
      const totalPrice = price * quantity;
      setTotalPrice(totalPrice);
    }
  }, [quantity, productInfo]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize')); // Manually trigger resize event
  }, [images]);


  const handlenavigate = () => {
    // console.log(user_id, product_id)
    navigate('/direct_checkout', { state: { user_id: user_id, product_id: product_id, quantity: quantity, total_price: totalPrice } })


  }



  const handleSubmit = async (e, selectedProduct, total_price, count, user_id) => {
    if (user_id > 0) {
      e.preventDefault();
      // console.log("Product ID is here : ", selectedProduct)
      const product_id = selectedProduct;
      // console.log("Product count : ", count, "user ID : ", user_id, "Total Price:", total_price, "Product id:", product_id)

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/add_to_cart/${product_id}/${count}/${total_price}/${user_id}`,

        );
        // console.log(res)
        if (res.status == 200) {
          alert("Added to Cart Successfully!")
          window.location.reload()
        }

      } catch (error) {
        // console.error("Error assigning subject data:", error.message);
      }
    }
    else {
      alert("Please first login with your credentials..!!")
    }


  }
  return (
    <>
  
    <div className="container" style={{ marginTop: '5%' }}>
      <div className="product_card">
        {/* <div className="product_thumbnails">
          {images.map((image, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_BASE_URL}/view_doc/${image}`}
              alt={`Thumbnail ${index}`}
              onClick={() => setSelectedImageIndex(index)}
            />
          ))}
        </div> */}
        <div className="product_img">
          {images.length > 0 && (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Product Image',
                  isFluidWidth: true,
                  src: `${process.env.REACT_APP_BASE_URL}/view_doc/${images[selectedImageIndex]}`,
                  height: 800
                },
                largeImage: {
                  src: `${process.env.REACT_APP_BASE_URL}/view_doc/${images[selectedImageIndex]}`,
                  width: 1200,
                  height: 1800
                },
                enlargedImagePosition: 'over',
                lensStyle: { backgroundColor: 'rgb(0,0,0,.6)' }
              }}
            />
          )}
        </div>
        {productInfo.map((data, i) => (
          <div className="product_details" key={i}>
            <h2>{data.product_name}</h2>
            <div className="sub_info">
              <p>Description: {data.product_desc}</p>
              <p>Size: {data.product_size}</p>
              <div className='d-flex gap-2'>
                <p>Price: ₹ {totalPrice}/-</p>
                <p style={{ color: 'red', textDecoration: 'line-through' }}>M.R.P ₹ {data.product_price}</p>
              </div>

              <p>Quantity</p>
              <InputGroup className="mb-3" style={{ width: '200px' }}>
                <Button variant="outline-danger" onClick={decreaseQuantity}>-</Button>
                <FormControl
                  aria-label="Quantity"
                  value={quantity}
                  className='form_control_product'
                  readOnly
                  style={{ textAlign: 'center' }}
                />
                <Button variant="outline-success" onClick={increaseQuantity}>+</Button>
              </InputGroup>
            </div>
            <div className="buttons">
              <button className="btn btn-warning" onClick={(e) => handleSubmit(e, data.product_id, totalPrice, quantity, user_id)}>Add Item</button>
              <button className="btn btn-warning" onClick={handlenavigate}>Buy Now</button>
            </div>
          </div>
        ))}
      </div>
     

    </div>
    {/* <div className=''>
     {product_id > 0 ? (
      <div className="testimonials">
        <NewSlider product_id={product_id} />
      </div>
    ) : (
      <>
      
      </>
    )}
    </div> */}
  </>
  );
};

export default Product;
