import React, { useState, useEffect } from 'react';
import logo from '../Photos/logo.png';
import { FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CartModal from './CartModal/CartModal';
import { useNavigate } from 'react-router-dom';
import Modal from '../Login/Modal';
import LoginModal from '../Login/LoginModal';


const NavBar = ({ handleUserLogin, handleLogout, user_id, token }) => {
    const [count, setCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_cart_count/${user_id}`
                );
                if (res.status === 200) {
                    setCount(res.data.count);
                }
            } catch (error) {
                // console.error("Error fetching cart count:", error.message);
            }
        };

        fetchData();
    }, [user_id]);

    const handleShopCart = () => {
        // console.log("user_id", user_id)
        navigate('/mycart', { state: { user_id: user_id } });

    }

    
    function handleLogout() {
        window.open('/','_self');
        localStorage.clear();
        // window.location.reload();
       
    }

    return (
        <>
            <div className="container" style={{ borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px' }}>
<div id="navbar">
                <header className=" mx-5 d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <a href="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                            <img className="bi" src={logo} width="90" height="90" role="img" aria-label="Bootstrap" alt="Logo" />
                        </a>
                    </div>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><Link to="/" className="nav-link px-2 link-dark">Home</Link></li>
                        {/* <li><Link to="/category" className="nav-link px-2 link-dark">Category</Link></li> */}
                        {/* <li><a href="#" className="nav-link px-2 link-dark">FAQs</a></li> */}
                        {user_id ? (
                        <li><Link to='/profile' className="nav-link px-2 link-dark">Profile</Link></li>
                        ):(<></>)}
                    </ul>

                    <div className="col-md-3 text-end">
                        {user_id > 0 ? (
                            <button type="button" className="btn btn-primary position-relative mx-2" onClick={handleShopCart}>
                                {count > 0 ?
                                    (<>
                                        <FaShoppingCart />Cart
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            {count}
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </>) :
                                    (
                                        <>
                                            <FaShoppingCart />Cart
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                0
                                                <span className="visually-hidden">unread messages</span>
                                            </span>
                                        </>
                                    )}

                            </button>
                        ) : (
                            <></>
                        )}


                        {/* Render CartModal only when isModalOpen is true */}

                        {/* Render login/logout buttons based on user_id */}
                        {user_id > 0 ? (
                            <button type="button" className="btn btn-outline-danger me-2" onClick={handleLogout}>Logout</button>
                        ) : (
                            <>
                                <button type="button" className="btn btn-outline-warning me-2" onClick={openModal}>Login</button>
                                <LoginModal isOpen={isModalOpen} onClose={closeModal} handleUserLogin={handleUserLogin} token={token} />
                                <Link to='/register'><button type="button" className="btn btn-warning">Register</button></Link>
                            </>
                        )}
                    </div>
                </header>
                </div>
            </div>
        </>
    );
};

export default NavBar;
