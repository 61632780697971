import React from 'react';
import { Card, Button }
    from 'react-bootstrap';
import './category.css'
import product_1 from '../Photos/product_1.png'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, Toaster } from 'sonner';

import { useNavigate } from 'react-router-dom';

const CategoryData = ({ role }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [item, setItem] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {


            // console.log("Data aya  : ", process.env.REACT_APP_BASE_URL)


            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_category_data`
                );
                if (res.status === 200) {
                    setItem(res.data);
                }
            } catch (error) {
                // console.error("Error fetching category data:", error.message);
            }
        };



        fetchData();

        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const deletecategory = async (e, id) => {
        // console.log("To be delete:", id)
        // /delete_category_data/:id
        try {
            // // console.log("actual img:", formData);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/delete_category_data/${id}`,
            );
            if (response.data.success === false) {
                toast.warning("Facing some issue")
            }
            else if (response.data.success === true) {
                toast.success(response.data.message)
                window.open('/', '_self')
            }

        } catch (error) {
            // // console.error("Error:", error);
        }
    }

    const handlenavigate = async (e, cat_id) => {
        if (screenWidth <= 767) {
            e.preventDefault();
        }
        // console.log("Category_ID : ", cat_id)
        navigate('/product_list', { state: { cat_id: cat_id } })
    }

    const handleeditcategory= async (e, cat_id) => {
        if (screenWidth <= 767) {
            e.preventDefault();
        }
        console.log("Category_ID : ", cat_id)
        navigate('/edit_catgory', { state: { cat_id: cat_id } })
    }
    return (

        <>


            <div className="category_head">
                <Toaster position="top-center" richColors />
                <h3>Category</h3>
                <p>Select the Category to see Products...</p>
            </div>
            <div className="hover_card">
                <div className="card__container">
                    {item.map((data, i) => (
                        <article className="card__article">
                            <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.cat_img}`} alt="image" className="card__img" />

                            <div className="card__data">
                                <span className="card__description"></span>
                                <h2>{data.name}</h2>
                                <div className='d-flex gap-5'>
                                    <button href="#" className=" btn btn-warning btn-sm" onClick={(e) => handlenavigate(e, data.ID)}>Shop</button>
                                    {role == 1 ? (
                                        <>
                                       <button href="#" className=" btn btn-primary btn-sm" onClick={(e) => handleeditcategory(e, data.ID)}>Edit</button>
                                        <button href="#" className=" btn btn-danger btn-sm" onClick={(e) => deletecategory(e, data.ID)}>Delete</button>
                                        </>
                                    ) : (<></>)}
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>

        </>


    );
};

export default CategoryData;
