import React, { useState, useEffect } from 'react';
import '../AddCategory/addcategory.css';
import { BsPlusCircle, BsXCircle } from 'react-icons/bs'; 
import axios from 'axios';

const EditHeroSection = () => {

    const [category, setCategory] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_category_data`
                );
                if (res.status === 200) {
                    // Assuming res.data is an array of objects
                    setCategory(res.data);
                }
            } catch (error) {
                // console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        category: '',
        images: []
    });

    const formDataToSend2 = new FormData();

    const handleClickUpload = (index) => {
        const fileInput = document.getElementById(`file-upload-${index}`);
        fileInput.click();
    };

    const handleImageUpload = (e, index) => {
        const files = e.target.files; // Get all selected files
        // console.log('Selected files:', files); // Check if the correct files are selected

        if (files.length > 0) {
            const updatedImageFiles = [...formData.images];

            // Replace the file at the specified index with the selected files
            updatedImageFiles.splice(index, 1, ...files);

            // console.log('Updated files array:', updatedImageFiles); // Check if files array is updated

            setFormData((prevFormData) => ({
                ...prevFormData,
                images: updatedImageFiles,
            }));
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeImage = (filelist) => {
        // console.log("Images aayi : ", filelist);

        const updatedFormData = { ...formData }; // Create a copy of formData

        for (let index = 0; index < filelist.length; index++) {
            const file = filelist[index];
            updatedFormData.images.push(file); // Push the file to the images array in updatedFormData
        }

        // Update the formData state with the updatedFormData
        setFormData(updatedFormData);

        // console.log("Form data with images:", updatedFormData);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('image', selectedFile)
        formDataToSend.append('categoryname', formData.category);
        // console.log("File List : ", formData.images);


        for (let index = 0; index < formData.images.length; index++) {
            const file = formData.images[index];
            formDataToSend.append('images', file);
        }


        try {
            // console.log("actual img:", formDataToSend);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/add_herosection_images`,
                formDataToSend
            );
            // console.log(response.data);
            if(response.data.success=true) {
                alert("Image Added Successfully")
                window.location.reload();
            }    
        } catch (error) {
            // console.error("Error:", error);
        }
    };

    const handleCancelImage = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [],
        }));
    };




    return (
        <div className="add_product mb-2" style={{marginTop:'10%'}}>
        <div className="container">
            <div className="add_product_container">
                <div className="register_head mb-3">
                    <h2>Edit Hero Section</h2>
                    <p>Please Select the image of Size(1000px * 350px)</p>
                </div>

                <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="upload_img d-flex justify-content-center">
                        <div className="">
                            <div className="upload-square">
                                {formData.images.length === 0 ? (
                                    <div className="upload-icon text-center">
                                        <BsPlusCircle onClick={() => handleClickUpload(1)} />
                                        <input
                                            id={`file-upload-1`}
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                handleChangeImage(files);
                                            }}
                                        />
                                        
                                        <p className="upload-text mt-2">Upload Image</p>
                                    </div>
                                ) : (
                                    <div className="uploaded-image-container">
                                    <img
                                        src={URL.createObjectURL(formData.images[0])}
                                        alt="Uploaded Image"
                                        className="uploaded-image"
                                    />
                                    <BsXCircle className="cancel-icon" onClick={handleCancelImage} />
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-5 d-flex justify-content-center">
                        <button type="submit" className="col-12 btn" style={{backgroundColor:'#095809',color:'white'}}>Add Image</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    );
};

export default EditHeroSection;
