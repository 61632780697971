import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import './inventory.css';

import { useNavigate } from 'react-router-dom';
// import productData from '../ProductCart/content';
const Inventory = () => {

    const navigate = useNavigate()
    const [productInfo, setProductInfo] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [stockCount, setStockCount] = useState(0);
    const [mainproduct, setMainProduct] = useState([]);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getinventory`);
                if (res.status === 200) {
                    setProductInfo(res.data);
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        fetchData();
    }, []);

    const handleShowModal = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setStockCount(0); // Reset stock count when modal is closed
    };

    const handleAddStock = async () => {
        const product_id = selectedProduct.product_id;
        const quantity = stockCount;
        try {
            // Send API request to add stock
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_stock/${product_id}/${quantity}`);
            if (res.status === 200) {
                alert("Updated Successfully");
                // Update product info after adding stock
                const updatedProductInfo = productInfo.map(product => {
                    if (product.product_id === selectedProduct.product_id) {
                        return { ...product, product_quantity: product.product_quantity + stockCount };
                    }
                    return product;
                });
                setProductInfo(updatedProductInfo);
                setShowModal(false);
            }
        } catch (error) {
            // console.error('Error adding stock:', error.message);
        }
    };

    async function handleDelete(pro_id) {
        // console.log("Product ID: ", pro_id)
        const product_id = pro_id;
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete_product/${product_id}`, {
            method: "POST",
            body: JSON.stringify({
                id: product_id
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const result = await res.json();
        window.location.reload();
    }

    const handleEdit =  async(product_id) => {
        
        // console.log("Product ID: ", mainproduct)
        navigate('/edit_product_details', { state: { product_data: product_id } })
    }

    return (
        <div className="container">
            <div className="category_head">
                <h3>Product Inventory</h3>
                <p className='text-center'>Here you will get all the added products...</p>
            </div>
            <div className="table-container">
            <div className="inventory_table">
                <div className="table">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Product ID</th>
                                <th scope="col">Product</th>
                                <th scope="col">Name</th>
                                <th scope="col">Category</th>
                                <th scope="col">Stock</th>
                                <th scope="col">Size</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productInfo.map((data, i) => (
                                <tr className={`${data.product_quantity < 10 ? 'table-danger' : 'table'}`} key={i}>
                                    <td>{data.product_id}</td>
                                    <td id="table_img">
                                        <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.product_img}`} alt="..." />
                                    </td>
                                    <td>{data.product_name}</td>
                                    <td>{data.product_category}</td>
                                    <td>{data.product_quantity}</td>
                                    <td>{data.product_size}</td>
                                    <td>₹ {data.product_price}/-</td>
                                    <td><button className='btn btn-warning btn-sm' onClick={() => handleShowModal(data)}>Add Stock</button></td>
                                    <td><button className='btn btn-danger btn-sm' onClick={() => handleDelete(data.product_id)}>Delete</button></td>
                                    <td><button className='btn btn-primary btn-sm' onClick={() => handleEdit(data.product_id)}>Edit</button></td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="inventory-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Stock</Modal.Title>
                </Modal.Header>
                <Modal.Body dialogClassName="inventory-modal-body">
                    {selectedProduct && (
                        <div className="modal-content inventory-modal-content">
                            <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${selectedProduct.product_img}`} alt="..." />
                            <br />
                            <h5>{selectedProduct.product_name}</h5>
                            <p>Present Stock: {selectedProduct.product_quantity}</p>
                            <div className="mb-3">
                                <label htmlFor="stockCount" className="form-label">Add Stock:</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id="stockCount"
                                    value={stockCount}
                                    onChange={(e) => setStockCount(parseInt(e.target.value))}
                                    aria-describedby="emailHelp"
                                />
                                <div id="emailHelp" className="form-text">Add the Stocks here...</div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="success" onClick={handleAddStock}>
                        Add Stock
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Inventory;
