


import { React, useState, useEffect } from 'react'
import './Bill.css'
import logo from '../Photos/logo.png'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';

const Bill = () => {

    const [data, setData] = useState([])
    const [totalPrices, setTotalPrices] = useState([]);


    const location = useLocation();
    const user_data = location.state?.userData || [];
    const total_price = location.state?.total_price || 0;
    // console.log("Total Price: ", total_price)
    // console.log("User Bill Data : ", user_data)

    useEffect(() => {
        const new_price = total_price + 100;
        setTotalPrices(new_price)
        formatDate(user_data.order_date)
    }, [total_price])

    const formatDate = (dateString) => {
        const inputDate = new Date(dateString);
        const day = inputDate.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Get month (zero-based index) and pad with leading zero if needed
        const year = inputDate.getFullYear().toString(); // Get full year

        return `${day}-${month}-${year}`;
    };


    const handleDownloadPDF = () => {
        const input = document.getElementById('print-area');
        const pdfWidth = input.offsetWidth;
        const pdfHeight = input.offsetHeight;

        html2canvas(input, { width: pdfWidth, height: pdfHeight }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('invoice.pdf');
        });
    };

    const handlePrintPDF = () => {
        window.print();
    }

    let totalPriceWithExtra = 0;


    
    return (
        <div>
            <div class="invoice-wrapper" id="print-area">
                <div class="invoice">
                    <div class="invoice-container">

                        {user_data.map((data, i) => (
                            <div class="invoice-head">
                                <div class="invoice-head-top">
                                    <div class="invoice-head-top-left text-start">
                                        <img src={logo} />
                                    </div>
                                    <div class="invoice-head-top-right text-end">
                                        <h3>Invoice</h3>
                                    </div>
                                </div>
                                <div class="hr"></div>
                                <div class="invoice-head-middle">
                                    <div class="invoice-head-middle-left text-start">
                                        <p><span class="text-bold">Date</span>: {formatDate(data.order_date)}</p>
                                    </div>

                                    <div class="invoice-head-middle-right text-end">
                                        <p><span class="text-bold">Invoice No:</span> {data.order_id}</p>
                                    </div>
                                </div>
                                <div class="hr"></div>
                                <div class="invoice-head-bottom">
                                    <div class="invoice-head-bottom-left">
                                        <ul>
                                            <li class='text-bold'>Invoiced To:</li>
                                            <li>{data.firstName} {data.lastName}</li>
                                            <li>{data.address1}, {data.street_add}</li>
                                            <li>{data.landmark},</li>
                                            <li>{data.city}, {data.state} {data.country}</li>
                                        </ul>
                                    </div>
                                    <div class="invoice-head-bottom-right">
                                        <ul class="text-end">
                                            <li class='text-bold'>Pay To:</li>
                                            <li> Pop N Plate</li>
                                            <li>Mulund, Mumbai</li>
                                            <li>popnplate01@gmail.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div class="overflow-view">
                            {user_data.map((data, i) => (
                                <div class="invoice-body">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td class="text-bold">Sr no.</td>
                                                {/* <td class="text-bold">Order ID</td> */}
                                                <td class="text-bold">Product</td>
                                                <td class="text-bold">Size</td>
                                                <td class="text-bold">QTY</td>
                                                <td class="text-bold">Amount</td>
                                                {/* <td class="text-bold">Status</td> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{i + 1}</td>
                                                {/* <td>{data.order_id}</td> */}
                                                <td>{data.product_name}</td>
                                                <td>{data.product_size}</td>
                                                <td>{data.quantity}</td>
                                                <td>₹ {data.total_price}</td>
                                                {/* <td class="text-end">{data.name}</td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="invoice-body-bottom">
                                        <div class="invoice-body-info-item border-bottom">
                                            <div class="info-item-td text-end text-bold">Sub Total:</div>
                                            <div class="info-item-td text-end">₹ {data.total_price}</div>
                                        </div>
                                        <div class="invoice-body-info-item border-bottom">
                                            <div class="info-item-td text-end text-bold">Delivery Charges: </div>
                                            <div class="info-item-td text-end">₹ {100}</div>
                                        </div>
                                        <div class="invoice-body-info-item">
                                            <div class="info-item-td text-end text-bold">Total:</div>
                                            
                                            <div className="info-item-td text-end">₹ {parseFloat(data.total_price) + 100}/-</div>
                                        </div>

                                    </div>
                                </div>
                            ))}

                        </div>
                        <div class="invoice-foot text-center">
                            <p><span class="text-bold text-center">NOTE:&nbsp;</span>This is computer generated receipt and does not require physical signature.</p>

                            <div class="invoice-btns">

                                <button className='btn btn-warning invoice-btn' onClick={handlePrintPDF}>Download</button>
                                {/* <button className='btn btn-danger invoice-btn' onClick={handleDownloadPDF}>Download</button> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bill




